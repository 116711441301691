import {ChangeEventHandler, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {styled} from 'styled-components';

import {ButtonAsLink} from '@shared-web/components/core/button';
import {Checkbox} from '@shared-web/components/core/checkbox';
import {DropZoneDiv} from '@shared-web/components/core/drop_zone_div';
import {Custom} from '@shared-web/lib/react';

import {apiCall} from '@src/lib/api';

interface IconOptimizePageProps {}

function removeCopyright(svg?: string): string | undefined {
  return svg?.replaceAll(/<text .*>[^<]*<\/text>/gu, '');
}

const invisibleElems = new Set(['defs', 'g', 'foreignObject', 'svg', 'style', 'title', 'desc']);

function filterSVGToVisibleElements(svg: Element): Element[] {
  function flatten(ops: Element[], n: Element): Element[] {
    ops.push(n);
    if (n.children.length > 0) {
      [...n.children].reduce(flatten, ops);
    }
    return ops;
  }

  const result = [svg].reduce(flatten, []).filter(elem => {
    const parentElement = elem.parentElement;
    return (
      elem.tagName.length > 0 &&
      !invisibleElems.has(elem.tagName) &&
      (elem.getBoundingClientRect().width > 0 || elem.getBoundingClientRect().height > 0) &&
      !parentElement?.hasAttribute('mask') &&
      parentElement?.tagName !== 'defs' &&
      (getComputedStyle(elem).stroke !== 'none' || getComputedStyle(elem).fill !== 'none')
    );
  });
  return result;
}

const DEFAULT_PRECISION = 2;

export const IconOptimizePage: Custom<IconOptimizePageProps, 'div'> = props => {
  const [svgSource, setSvgSource] = useState<string>();
  const svgCleanRef = useRef<HTMLDivElement>(null);
  const svgClean = useMemo(() => removeCopyright(svgSource), [svgSource]);
  const [svgCropped, setSvgCropped] = useState<string>();
  const [svgOptimized, setSvgOptimized] = useState<{precision: number; svg: string}[]>();
  const [precision, setPrecision] = useState<number>(DEFAULT_PRECISION);
  const [showOriginal, setShowOriginal] = useState(false);

  const handleFilesDropped = useCallback((files: File[]) => {
    const file = files[0];
    if (!file) {
      return;
    }
    file
      .text()
      .then(setSvgSource)
      .catch((err: unknown) => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    // Get the svg without copyright
    const el = svgCleanRef.current?.children.item(0);
    if (!el) {
      return;
    }

    // Remove size attributes
    el.setAttribute('viewBox', '0 0 100 100');
    el.setAttribute('width', '100px');
    el.setAttribute('height', '100px');

    // Find the bounding coordinates across all visible elements
    let top = Number.POSITIVE_INFINITY;
    let right = Number.NEGATIVE_INFINITY;
    let bottom = Number.NEGATIVE_INFINITY;
    let left = Number.POSITIVE_INFINITY;
    for (const x of filterSVGToVisibleElements(el)) {
      const rect = x.getBoundingClientRect();
      let newTop = rect.top;
      let newLeft = rect.left;
      let newBottom = rect.bottom;
      let newRight = rect.right;
      const stroke = getComputedStyle(x)['stroke'];
      const strokeWidth = Number(getComputedStyle(x).strokeWidth.replace('px', ''));
      if (stroke !== 'none') {
        newTop -= strokeWidth / 2;
        newLeft -= strokeWidth / 2;
        newBottom += strokeWidth / 2;
        newRight += strokeWidth / 2;
      }
      if (newTop < top) {
        top = newTop;
      }
      if (newRight > right) {
        right = newRight;
      }
      if (newBottom > bottom) {
        bottom = newBottom;
      }
      if (newLeft < left) {
        left = newLeft;
      }
    }

    // Update viewbox
    const dom = new DOMParser();
    const svgEl = dom.parseFromString(el.outerHTML, 'image/svg+xml').documentElement;
    svgEl.setAttribute(
      'viewBox',
      `${left.toFixed(2)} ${top.toFixed(2)} ${(right - left).toFixed(2)} ${(bottom - top).toFixed(2)}`
    );
    svgEl.removeAttribute('width');
    svgEl.removeAttribute('height');
    setSvgCropped(svgEl.outerHTML);
  }, [svgClean]);

  useEffect(() => {
    if (svgCropped === undefined) {
      return undefined;
    }
    setSvgOptimized(undefined);
    apiCall('POST /optimize-icon', {svg: svgCropped})
      .then(res => setSvgOptimized(res))
      .catch((err: unknown) => console.error(err));
  }, [svgCropped]);

  const handlePrecisionChange = useCallback<ChangeEventHandler<HTMLInputElement>>(evt => {
    setPrecision(parseFloat(evt.currentTarget.value));
  }, []);

  const svgOptimizedAtPrecision = useMemo(() => {
    const svg = svgOptimized?.find(d => d.precision === precision)?.svg;
    if (svg === undefined) {
      return undefined;
    }
    const domParser = new DOMParser();
    const parsed = domParser.parseFromString(svg, 'image/svg+xml').documentElement;
    const viewBox = parsed.getAttribute('viewBox') ?? '';
    const elements = [...parsed.children].map(c => c.outerHTML);
    const element =
      elements.length > 1
        ? `    <g>${elements.map(e => `      ${e}`.replaceAll(/ xmlns="[^"]*"/gu, '')).join('\n')}</g>`
        : elements.map(e => `    ${e}`.replaceAll(/ xmlns="[^"]*"/gu, '')).join('\n');
    return {svg, viewBox, element};
  }, [precision, svgOptimized]);

  const handleCopyToClipboardClick = useCallback(async () => {
    if (svgOptimizedAtPrecision === undefined) {
      return;
    }
    await navigator.clipboard.writeText(svgOptimizedAtPrecision.svg);
  }, [svgOptimizedAtPrecision]);

  let content = <></>;
  if (svgSource !== undefined) {
    content =
      svgOptimizedAtPrecision === undefined || svgCropped === undefined ? (
        <Loading>Optimizing...</Loading>
      ) : (
        <>
          <SvgWrapper
            dangerouslySetInnerHTML={{
              __html: showOriginal ? svgCropped : svgOptimizedAtPrecision.svg,
            }}
          ></SvgWrapper>
          <OptimzedWrapper>
            <input
              type="range"
              min={0}
              max={5}
              value={precision}
              onChange={handlePrecisionChange}
            />
            <div>{`-${Math.round(100 * ((svgCropped.length - svgOptimizedAtPrecision.svg.length) / svgCropped.length))}%`}</div>
            <Checkbox checked={showOriginal} syncState={setShowOriginal}>
              Show original
            </Checkbox>
          </OptimzedWrapper>
          <ButtonAsLink onClickAsync={handleCopyToClipboardClick}>Copy to clipboard</ButtonAsLink>
          <SvgPre>
            {`
import {SvgIconData} from '@shared-web/components/core/svg_icon';

export const newIcon: SvgIconData = {
viewBox: '${svgOptimizedAtPrecision.viewBox}',
element: (
${svgOptimizedAtPrecision.element}
),
};
`.trimStart()}
          </SvgPre>
        </>
      );
  }

  return (
    <Wrapper {...props}>
      <DropZoneDiv onFilesDropped={handleFilesDropped} accept="image/svg">
        <DropZoneContent>Load SVG icon</DropZoneContent>
      </DropZoneDiv>
      {svgClean !== undefined ? (
        <SvgWorkbench dangerouslySetInnerHTML={{__html: svgClean}} ref={svgCleanRef}></SvgWorkbench>
      ) : (
        <></>
      )}
      {content}
    </Wrapper>
  );
};

IconOptimizePage.displayName = 'IconOptimizePage';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  padding: 16px;
`;

const SvgWrapper = styled.div`
  display: flex;
  width: 300px;
  & > svg {
    border: solid 2px black;
  }
`;

const SvgWorkbench = styled.div`
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100px;
  height: 100px;
  border: none;
`;

const OptimzedWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const SvgPre = styled.pre`
  width: 100%;
  white-space: break-spaces;
  font-family: monospace;
  background: #dddddd;
  padding: 16px;
  border-radius: 8px;
  font-size: 12px;
`;

const Loading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 300px;
`;

const DropZoneContent = styled.div`
  background-color: white;
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
`;
