import {Arr, Num, Obj, Str} from '@shared/api/core/api_schema';

export const APP_API = {
  '/load': {
    POST: {
      req: Obj({query: Str(), page: Num()}),
      res: Arr(Obj({href: Str(), img: Str()})),
    },
  },
  '/load-icon': {
    POST: {
      req: Obj({icon: Str()}),
      res: Obj({base64: Str()}),
    },
  },
  '/optimize-icon': {
    POST: {
      req: Obj({svg: Str()}),
      res: Arr(Obj({precision: Num(), svg: Str()})),
    },
  },
};
