import {ChangeEventHandler, useCallback, useEffect, useLayoutEffect, useRef, useState} from 'react';
import {styled} from 'styled-components';

import {Button, UnthemedLink} from '@shared-web/components/core/button';
import {Input} from '@shared-web/components/core/input';
import {Custom} from '@shared-web/lib/react';
import {useStateRef} from '@shared-web/lib/use_state_ref';

import {apiCall} from '@src/lib/api';

interface IconSearchPageProps {}

// eslint-disable-next-line @typescript-eslint/no-magic-numbers
const SIZES = [32, 48, 64, 100, 200, 300, 500];
const DEFAULT_SIZE_INDEX = 2;

export const IconSearchPage: Custom<IconSearchPageProps, 'div'> = props => {
  //
  // Query and data fetching
  //
  const [query, setQuery] = useState('');
  const queryForFetch = useRef({query, endReached: false});
  const [icons, setIcons] = useState<{href: string; img: string}[]>([]);
  const lastQuery = useRef<{query: string; page: number} | undefined>(); // last query used to load the icons
  const [isFetching, setIsFetching, isFetchingRef] = useStateRef(false);
  const shouldLoadNextPage = useRef(false);

  const fetchIcons = useCallback(
    async (force?: boolean) => {
      // Clear the icon if query is empty
      if (queryForFetch.current.query.length === 0) {
        setIcons([]);
        lastQuery.current = undefined;
        return;
      }

      // Don't fetch if we are already
      if (isFetchingRef.current && !force) {
        return;
      }

      // Don't fetch if the end was reached
      if (queryForFetch.current.endReached) {
        return;
      }

      setIsFetching(true);

      // Clear the icons if the query changed
      if (!lastQuery.current || lastQuery.current.query !== queryForFetch.current.query) {
        lastQuery.current = {query: queryForFetch.current.query, page: 0};
        setIcons([]);
      }

      // Load the next page
      const reqQuery = lastQuery.current.query;
      const reqPage = lastQuery.current.page;
      const res = await apiCall('POST /load', {query: reqQuery, page: reqPage + 1}).catch(
        (err: unknown) => {
          setIsFetching(false);
          throw err;
        }
      );

      // Continue only if the query has not changed in between
      if (lastQuery.current.query !== reqQuery || lastQuery.current.page !== reqPage) {
        return;
      }

      // Update the icons
      setIsFetching(false);
      setIcons(icons => {
        const newIcons = [...icons];
        const ids = new Set(icons.map(i => i.href));
        for (const icon of res) {
          if (!ids.has(icon.href)) {
            ids.add(icon.href);
            newIcons.push(icon);
          }
        }
        const endReached = newIcons.length === icons.length;
        queryForFetch.current.endReached = endReached;
        return newIcons;
      });
      lastQuery.current = {query: reqQuery, page: reqPage + 1};
    },
    [isFetchingRef, queryForFetch, setIsFetching]
  );

  const handleSubmit = useCallback(async () => {
    queryForFetch.current = {query, endReached: false};
    await fetchIcons(true);
  }, [fetchIcons, query]);

  //
  // Autofetching
  //

  // Wait for DOM update after each change to `icons`
  useLayoutEffect(() => {
    // Wait for first paint
    requestAnimationFrame(() => {
      // Wait for next frame to so the IntersectionObserver has time to update
      requestAnimationFrame(() => {
        // Force the observer to refresh
        runObserver.current?.();
        if (shouldLoadNextPage.current) {
          fetchIcons().catch(() => {});
        }
      });
    });
  }, [fetchIcons, icons]);

  // Trigger auto fetching of next page when footer is visible
  const footerRef = useRef<HTMLDivElement>(null);
  const footerTargetRef = useRef<HTMLDivElement>(null);
  const runObserver = useRef<() => void>();
  useEffect(() => {
    let footerVisible = false;
    let footerTargetVisible = false;

    const callback: IntersectionObserverCallback = entries => {
      const previousShouldLoadNextPage = footerVisible || footerTargetVisible;
      for (const entry of entries) {
        if (entry.target === footerRef.current) {
          footerVisible = entry.isIntersecting;
        } else if (entry.target === footerTargetRef.current) {
          footerTargetVisible = entry.isIntersecting;
        }
      }
      const currentShouldLoadNextPage = footerVisible || footerTargetVisible;
      shouldLoadNextPage.current = currentShouldLoadNextPage;
      if (!previousShouldLoadNextPage && currentShouldLoadNextPage) {
        fetchIcons().catch(() => {});
      }
    };

    const observer = new IntersectionObserver(callback);
    if (footerRef.current && footerTargetRef.current) {
      observer.observe(footerRef.current);
      observer.observe(footerTargetRef.current);
    }

    runObserver.current = () => {
      callback(observer.takeRecords(), observer);
    };

    return () => {
      observer.disconnect();
    };
  }, [fetchIcons]);

  //
  // Handling of icon size in the UI
  //
  const [iconSizeIndex, setIconSizeIndex] = useState(DEFAULT_SIZE_INDEX);

  const handleSizeChange = useCallback<ChangeEventHandler<HTMLInputElement>>(e => {
    setIconSizeIndex(e.currentTarget.valueAsNumber);
  }, []);

  //
  // Rendering
  //
  return (
    <Wrapper {...props}>
      <Form>
        <Input value={query} syncState={setQuery} autoFocus width="100%" />
        <Button onClickAsync={handleSubmit} keyboardSubmit>
          Submit
        </Button>
      </Form>
      {icons.length > 0 ? (
        <SizeForm>
          <input
            type="range"
            min={0}
            max={SIZES.length - 1}
            value={iconSizeIndex}
            onChange={handleSizeChange}
          />
        </SizeForm>
      ) : (
        <></>
      )}
      <Icons $iconMaxWidth={SIZES[iconSizeIndex] ?? 10}>
        {icons.map(icon => (
          <IconButton
            key={icon.href}
            target="_blank"
            href={`https://thenounproject.com${icon.href}`}
            style={{backgroundImage: `url(${icon.img})`}}
          >
            <div />
          </IconButton>
        ))}
      </Icons>
      <Footer
        ref={footerRef}
        data-query={lastQuery.current?.query}
        data-page={lastQuery.current?.page}
      >
        <FooterTarget ref={footerTargetRef}></FooterTarget>
        {isFetching ? <FooterContent>loading...</FooterContent> : <></>}
      </Footer>
    </Wrapper>
  );
};

IconSearchPage.displayName = 'IconSearchPage';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 8px;
  align-items: stretch;
`;

const Form = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const Icons = styled.div<{$iconMaxWidth: number}>`
  background-color: #ffffff;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(${p => p.$iconMaxWidth}px, 1fr));
`;

const SizeForm = styled.div`
  display: flex;
  align-items: center;
  max-width: 300px;
`;

const IconButton = styled(UnthemedLink)`
  background-color: #ffffff;
  width: 100%;
  padding-top: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  cursor: pointer;
  &:hover {
    background-color: #cccccc;
  }
`;

const Footer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
`;

const FooterTarget = styled.div`
  position: absolute;
  top: -90vh;
`;

const FooterContent = styled.div``;
