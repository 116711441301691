import {DragEvent, DragEventHandler, useCallback, useRef, useState} from 'react';
import {styled} from 'styled-components';

import {Custom} from '@shared-web/lib/react';

interface DropZoneDivProps {
  onFilesDropped: (files: File[]) => void;
  onError?: (err: unknown) => void;
  accept?: string;
  color?: string;
  activeColor?: string;
}

export const DropZoneDiv: Custom<DropZoneDivProps, 'div'> = props => {
  const {
    onFilesDropped,
    onError,
    accept,
    color = '#00000055',
    activeColor = '#00740088',
    ...rest
  } = props;
  const [isDraggingOver, setIsDraggingOver] = useState(false);

  const handleDragOver = useCallback<DragEventHandler>(evt => {
    if (!evt.dataTransfer.types.includes('Files')) {
      setIsDraggingOver(false);
      return;
    }
    evt.preventDefault();
    evt.stopPropagation();
    setIsDraggingOver(true);
  }, []);

  const handleDragLeave = useCallback<DragEventHandler>(evt => {
    evt.preventDefault();
    evt.stopPropagation();
    setIsDraggingOver(false);
  }, []);

  const handleDrop = useCallback<DragEventHandler>(
    evt => {
      evt.preventDefault();
      evt.stopPropagation();
      setIsDraggingOver(false);
      extractFiles(evt)
        .then(onFilesDropped)
        .catch((err: unknown) => onError?.(err));
    },
    [onError, onFilesDropped]
  );

  // Trigger native upload dialog when the dropzone is clicked
  const uploadInputRef = useRef<HTMLInputElement>(null);
  const handleUploadClick = useCallback(() => {
    if (uploadInputRef.current) {
      uploadInputRef.current.click();
    }
  }, []);

  // Callback when the user selected files with the native file dialog
  const handleFileChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    evt => {
      const {files} = evt.currentTarget;
      if (files === null) {
        return;
      }
      evt.currentTarget.files = null;
      onFilesDropped(fileListToArray(files));
    },
    [onFilesDropped]
  );

  return (
    <>
      <HiddenInput
        type="file"
        // multiple={multiple}
        accept={accept}
        ref={uploadInputRef}
        onChange={handleFileChange}
      />
      <Wrapper
        $dragging={isDraggingOver}
        $color={color}
        $activeColor={activeColor}
        {...rest}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        onClick={handleUploadClick}
      />
    </>
  );
};
DropZoneDiv.displayName = 'DropZoneDiv';

const Wrapper = styled.div<{$dragging: boolean; $color: string; $activeColor: string}>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 18px;
  overflow: hidden;

  color: ${p => (p.$dragging ? p.$activeColor : p.$color)};
  border: ${p => (p.$dragging ? p.$activeColor : p.$color)} 2px dashed;
  border-radius: 8px;

  cursor: pointer;
  &:hover {
    color: ${p => p.$activeColor};
    border-color: ${p => p.$activeColor};
  }

  transition: none 100ms ease-in-out;
  transition-property: color border-color;
`;

const HiddenInput = styled.input`
  display: none;
`;

//
// File lib
//

async function extractFiles(evt: DragEvent): Promise<File[]> {
  const dataTransferItems = evt.dataTransfer.items;
  const filesPromises: Promise<File[]>[] = [];
  // eslint-disable-next-line @typescript-eslint/prefer-for-of, unicorn/no-for-loop
  for (let i = 0; i < dataTransferItems.length; i++) {
    const entry = dataTransferItems[i]?.webkitGetAsEntry();
    if (!entry) {
      continue;
    }
    filesPromises.push(
      readEntries([entry]).then(async entries => {
        const files = await Promise.all(
          entries.map(
            async entry =>
              new Promise<File>((resolve, reject) => {
                entry.file(resolve, reject);
              })
          )
        );
        return files.flat();
      })
    );
  }

  const files = await Promise.all(filesPromises);
  return files.flat();
}

async function readEntries(entries: FileSystemEntry[]): Promise<FileSystemFileEntry[]> {
  const filesPromises: Promise<FileSystemFileEntry[]>[] = [];
  for (const entry of entries) {
    if (entry.isFile) {
      filesPromises.push(Promise.resolve([entry as FileSystemFileEntry]));
    } else {
      filesPromises.push(readDirectory(entry as FileSystemDirectoryEntry));
    }
  }
  const recursiveFiles = await Promise.all(filesPromises);
  return recursiveFiles.flat();
}

async function readDirectory(directory: FileSystemDirectoryEntry): Promise<FileSystemFileEntry[]> {
  const dirReader = directory.createReader();
  return new Promise((resolve, reject) => {
    dirReader.readEntries(results => {
      resolve(readEntries(results));
    }, reject);
  });
}

function fileListToArray(list: FileList): File[] {
  const files: File[] = [];
  // eslint-disable-next-line @typescript-eslint/prefer-for-of, unicorn/no-for-loop
  for (let i = 0; i < list.length; i++) {
    const file = list[i];
    if (!file) {
      continue;
    }
    files.push(file);
  }
  return files;
}
