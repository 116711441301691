import {FC} from 'react';
import {styled} from 'styled-components';

import {IconOptimizePage} from '@src/components/icon_optimize_page';
import {IconSearchPage} from '@src/components/icon_search_page';

interface MainPageProps {}

export const MainPage: FC<MainPageProps> = () => {
  return (
    <Wrapper>
      <Left />
      <Right />
    </Wrapper>
  );
};

MainPage.displayName = 'MainPage';

const Wrapper = styled.div`
  display: flex;
`;

const Left = styled(IconSearchPage)`
  width: 0;
  flex-grow: 1;
`;

const Right = styled(IconOptimizePage)`
  width: 0;
  flex-grow: 1;
`;
